import { DOM, CLASSES } from '../helpers/consts';

const initBurgerMenu = () => {
  let canChange = true;
  const $btn = $('.js-burger-btn');
  
  if(!$btn[0]) return;
  
  const $menu = $('.js-burger-menu');
  
  const toggleMenu = () => {
    if (!canChange) return;
    canChange = !canChange;

    $btn.toggleClass(CLASSES.active);
    $menu.fadeToggle(300, 'linear', () => {
      canChange = true;
    });
  };

  const clickHandle = (e) => {
    e.stopPropagation();
    toggleMenu();
  };
  $btn.on('click', clickHandle);

  DOM.$doc.on('click', (e) => {
    if ($(e.target).closest($menu).length === 0) {
      $btn.removeClass(CLASSES.active);
      $menu.fadeOut(300, 'linear', () => {
        canChange = true;
      });
    }
  });
};
export default initBurgerMenu;
