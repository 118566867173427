import { DOM, CLASSES } from '../helpers/consts';

const initGameBox = () =>{
  const $boxes = $('.js-game-box-main');

  $boxes.each((i, el)=> {
    const $box = $(el);

    const clickHandle = () => {
      $box.addClass(CLASSES.active)
    };

    $box.on('click', clickHandle);

    DOM.$doc.on('click', (e) => {
      if ($(e.target).closest($box).length === 0) {
        $box.removeClass(CLASSES.active);
      }
    });
  });
};

export default initGameBox;