import svg4everybody from 'svg4everybody';
import initBurgerMenu from './modules/burger';
import initLinks from './modules/links';
import initGameBox from './modules/gameBox';
import initGame from './modules/game';
import initRating from './modules/rating';
import initScrollPanel from './modules/scrollPanel';

import {DOM, IS_TOUCH, CLASSES} from './helpers/consts';

if(!IS_TOUCH){
  DOM.$body.addClass(CLASSES.noTouch);
}else{
  DOM.$body.addClass(CLASSES.touch);
};

const initHelpers = () => {
  svg4everybody();
};

const initModules = () => {
  initLinks();
  initBurgerMenu();
  initGameBox();
  initGame();
  initRating();
  initScrollPanel();
}

const readyFunc = () => {
  initHelpers();
  initModules();
}

DOM.$doc.ready(readyFunc);