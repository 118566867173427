import { DOM, IS_TOUCH, CLASSES } from '../helpers/consts';

const initScrollPanel = () => {
  const $panel = $('.js-scroll-panel');

  if(!$panel[0] || !IS_TOUCH) return;

  const scrollHandle = () => {
    const scrollPos = window.pageYOffset;
    if(scrollPos <= 4){
      $panel.removeClass(CLASSES.active);
    }else{
      $panel.addClass(CLASSES.active);
    }
  }
  
  DOM.$win.scroll(scrollHandle);

};
export default initScrollPanel;