import { IS_TOUCH, CLASSES } from '../helpers/consts';

const initRating = () => {
  const $rating = $('.js-rating');

  if(!$rating[0]) return;

  const $btns = $rating.find('.js-rating-btn');
  const btnsArr = $.makeArray($btns);

  const getPrevElems = (currentIndex) => {
    return btnsArr.filter((item) => {
      return +$(item).attr('data-index') <= currentIndex;
    });
  };

  const setActive = (arr) => {
    arr.forEach(element => {
      $(element).addClass(CLASSES.active)
    });
  };

  const restoreFromStorage = () => {
    const value = sessionStorage.getItem('ratingValue');
    if(value !== null) {
      setActive(getPrevElems(+value))
    }
  }
  restoreFromStorage();

  const mouseoverHandle = (e) => {
  const $btn = $(e.currentTarget);
      const index = +$btn.attr('data-index');
    const prevBtnsArr = getPrevElems(index)
    prevBtnsArr.forEach(element => {
      $(element).addClass(CLASSES.hover)
    });
  };

  const clickHandle = (e) => {
    const $btn = $(e.currentTarget);
    const index = +$btn.attr('data-index');
    const prevBtnsArr = getPrevElems(index);

    $btns.removeClass(CLASSES.active);
    setActive(prevBtnsArr);
    
    sessionStorage.setItem('ratingValue', `${index}`);
  };

  const mouseleaveHandle = () => {
    $btns.removeClass(CLASSES.hover)
  };

  if(!IS_TOUCH){
    $btns.hover(mouseoverHandle, mouseleaveHandle);
  }
  $btns.on('click', clickHandle);
};

export default initRating;