const initLinks = () =>{
  const $links = $('.js-link');

  $links.each((i, el)=> {
    const $link = $(el);
    const href = $link.attr('data-href');
    const isBlank = $link.attr('data-target') === 'blank';

    if(href && href !== '') {
      $link.on('click', (e) => {
        e.stopPropagation();
        
        if(isBlank){
          window.open(href);
        }else{
          window.location.href = href;
        }
      });
    }
  });
};

export default initLinks;