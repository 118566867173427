import { CLASSES } from '../helpers/consts';

const initGame = () =>{
  const $gameBlock = $('.js-game-block');

  if(!$gameBlock[0]) return;

  const $gameBlockInner = $gameBlock.find('.js-game-block-inner');
  const $gameBlockBtn = $gameBlock.find('.js-game-block-btn');
  const url = $gameBlock.attr('data-url');
  
  const clickHandle = () => {
    const iframe = `<iframe scrolling="no" frameborder="0" width="100%" height="100%" allowfullscreen="allowfullscreen" src="${url}"/>`;
    
    $gameBlockInner.append(iframe);
    $gameBlock.addClass(CLASSES.active);
  }

  $gameBlockBtn.one('click', clickHandle);


};

export default initGame;