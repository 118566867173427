import isTouch from './detectTouch';

export const DOM = {
  $win: $(window),
  $body: $('body'),
  $doc: $(document),
  $htmlBody: $('html, body')
};

export const CLASSES = {
  active: 'is-active',
  open: 'is-open',
  scrolled: 'is-scrolled',
  visible: 'is-visible',
  hidden: 'is-hidden',
  sent: 'is-sent',
  required: 'is-required',
  invalid: 'is-not-valid',
  touch: 'is-touch',
  noTouch: 'no-touch',
  overflowed: 'is-overflowed',
  menu: 'is-menu-opened',
  navScrolled: 'is-nav-scrolled',
  modalOpen: 'is-modal-open',
  hover: 'is-hover'
};

export const IS_TOUCH = isTouch();
